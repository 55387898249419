<template>
  <component :is="featherIcon" />
</template>

<script>
import * as FeatherIcons from 'vue-feather-icons'

export default {
  props: {
    iconName: {
      type: String,
      required: true,
    },
  },
  computed: {
    featherIcon() {
      return FeatherIcons[this.iconName]
    },
  },
}
</script>
