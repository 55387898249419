<template>
  <BadgeStyled :color="color" :backgroundColor="backgroundColor" :radius="radius" :width="width">
    <FeatherIconAtom :iconName="indicator" :size="12" />
    {{ text }}
  </BadgeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'
import FeatherIconAtom from './FeatherIconAtom.vue'

const BadgeStyled = styled('span')`
  ${flexCenter}
  min-width: 4rem;
  width: ${({ width }) => width};
  padding: 5px;
  height: 1.2rem;
  border-radius: ${({ radius }) => radius};
  color: ${({ color }) => color};
  background: ${({ backgroundColor }) => backgroundColor};
`

export default {
  inject: ['theme'],
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      require: false,
      default: 'fit-content',
    },
    radius: {
      type: String,
      require: false,
      default: '10px',
    },
    indicator: {
      type: String,
      require: false,
      default: 'check',
    },
  },
  components: {
    BadgeStyled,
    FeatherIconAtom,
  },
}
</script>
