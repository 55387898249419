var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CardStyled', [_c('TitleStyled', [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('TotalStyled', [_vm._v(" " + _vm._s(_vm.total) + " ")]), _c('TrendStyled', [_c('Badge', {
    attrs: {
      "text": _vm.trend.percent,
      "color": '#fff',
      "backgroundColor": _vm.trend.color
    }
  })], 1), _c('GraphStyled', [_c('SimpleLineChart', {
    attrs: {
      "series": _vm.series,
      "xAxis": _vm.xAxis
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }