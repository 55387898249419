<template>
  <SimpleLineChartStyled>
    <VChart :option="option" :updateOptions="{ notMerge: true }" autoresize />
  </SimpleLineChartStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js' // eslint-disable-line

import { use } from 'echarts/core'
import { LinearGradient } from 'echarts/lib/util/graphic'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import { GraphicComponent, GridComponent, VisualMapComponent } from 'echarts/components'
import VChart from 'vue-echarts'

use([CanvasRenderer, LineChart, GraphicComponent, GridComponent, VisualMapComponent, LinearGradient])

const SimpleLineChartStyled = styled('div')`
  height: 100%;
  width: 100%;
`

export default {
  props: {
    series: {
      type: Array,
      required: true,
    },
    grid: {
      type: Object,
      required: false,
      default: () => ({
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
      }),
    },
    visualMap: {
      type: Object,
    },
    xAxis: {
      type: Object,
      required: false,
      default: () => ({
        type: 'value',
        show: false,
      }),
    },
    yAxis: {
      type: Object,
      required: false,
      default: () => ({
        type: 'value',
        show: false,
      }),
    },
  },
  components: {
    SimpleLineChartStyled,
    VChart,
  },
  data() {
    return {
      initOptions: {
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        grid: this.grid,
        visualMap: this.visualMap,
      },
    }
  },
  computed: {
    option() {
      const option = {
        ...this.initOptions,
      }
      option.series = this.series.map(s => {
        return {
          ...s,
          symbol: 'circle',
          symbolSize: 5,
          areaStyle: {
            color: new LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: s.itemStyle.color,
              },
              {
                offset: 0.5,
                color: chroma(s.itemStyle.color).alpha(0.5).brighten(1).css(),
              },
              {
                offset: 1,
                color: chroma(s.itemStyle.color).alpha(0).brighten(1).css(),
              },
            ]),
          },
        }
      })
      return option
    },
  },
}
</script>
