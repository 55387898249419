var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BadgeStyled', {
    attrs: {
      "color": _vm.color,
      "backgroundColor": _vm.backgroundColor,
      "radius": _vm.radius,
      "width": _vm.width
    }
  }, [_c('FeatherIconAtom', {
    attrs: {
      "iconName": _vm.indicator,
      "size": 12
    }
  }), _vm._v(" " + _vm._s(_vm.text) + " ")], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }