export const getChartTrendColors = (trendValue, increasingTrendColor, theme) => {
  let chartColor = theme.isDark ? '#fff' : '#333'
  let color = theme.isDark ? theme.colors.headerBG : chartColor

  if (increasingTrendColor !== 'NEUTRAL') {
    if (trendValue > 0) {
      color = increasingTrendColor === 'GREEN' ? theme.colors.atomic.green : theme.colors.atomic.red
      chartColor = increasingTrendColor === 'GREEN' ? theme.colors.atomic.chart.green : theme.colors.atomic.chart.red
    } else if (trendValue < 0) {
      color = increasingTrendColor === 'GREEN' ? theme.colors.atomic.red : theme.colors.atomic.green
      chartColor = increasingTrendColor === 'GREEN' ? theme.colors.atomic.chart.red : theme.colors.atomic.chart.green
    }
  }

  return {
    chartColor,
    color,
  }
}
