<template>
  <CardStyled>
    <TitleStyled>
      {{ title }}
    </TitleStyled>
    <TotalStyled>
      {{ total }}
    </TotalStyled>
    <TrendStyled>
      <Badge :text="trend.percent" :color="'#fff'" :backgroundColor="trend.color" />
    </TrendStyled>
    <GraphStyled>
      <SimpleLineChart :series="series" :xAxis="xAxis" />
    </GraphStyled>
  </CardStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import SimpleLineChart from '../Atoms/SimpleLineChart'
import Badge from '../Atoms/Badge'

const CardStyled = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  padding: 1.65rem 1.5rem 1.875rem 1.5rem;
  box-sizing: border-box;
  grid-template-areas:
    'title'
    'total'
    'trend'
    'graph';
  grid-template-rows: 1fr 1fr 1fr 30%;
  grid-template-columns: 1fr;
`

const TitleStyled = styled('div')`
  grid-area: title;
  display: flex;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
`
const TotalStyled = styled('div')`
  grid-area: total;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: 500;
`

const TrendStyled = styled('div')`
  grid-area: trend;
  display: flex;
  justify-content: center;
  align-items: center;
`
const GraphStyled = styled('div')`
  grid-area: graph;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.25s ease;
`
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    total: {
      required: true,
      type: String,
    },
    trend: {
      required: true,
      type: Object,
    },
    series: {
      required: true,
      type: Array,
    },
    xAxis: {
      type: Object,
      required: false,
    },
  },
  components: {
    SimpleLineChart,
    Badge,
    CardStyled,
    TitleStyled,
    TotalStyled,
    TrendStyled,
    GraphStyled,
  },
}
</script>
